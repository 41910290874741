
import Vue from "vue";
import NavLink from "@/components/sidebar/navigatelink/Navlink.vue"
export default Vue.extend({
  components: {
    NavLink,
  },
  props: ["menus", "showmenu", "isOpen"],
  data() {
    return {
      isFixed: false
    };
  },
  methods: {
    sidebarToggleFixe() {
      this.isFixed = !this.isFixed
    }
  }
});
