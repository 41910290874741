
import Vue from 'vue';
import Sidebar from './components/sidebar/Sidebar.vue';
export default Vue.extend({
  name: 'App',
  components: {
    Sidebar
  },
  data() {
    return {
      menus: [
        {
          link: "/working-app",
          titleMenu: "Accueil",
          menuIcon: "home"
        },
        {
          link: "/schedule-app",
          titleMenu: "Gestion des rendez-vous",
          menuIcon: "calendar_month"
        },
        {
          link: "/content-app",
          titleMenu: "Gestion de contenus",
          menuIcon: "edit_document"
        },
        {
          link: "/tchat-app",
          titleMenu: "Méssagerie",
          menuIcon: "sms"
        },
        {
          link: "/hospi-app",
          titleMenu: "Hospi Gestion",
          menuIcon: "local_hospital"
        },
        {
          link: "/service-app",
          titleMenu: "Gestionnaire de services",
          menuIcon: "design_services"
        },
      ],
      showmenu: false,
      isOpen: false,
    };
  },
  // beforeCreate() {
  //   if (!sessionStorage.getItem('token')) {
  //     window.history.pushState(null, '', '/landing-app');
  //   }
  // },
});
