
import Vue from "vue";
export default Vue.extend({
  name: "Navlink",
  props: ["title", "link", "icon", "subMenus", "showmenu", "isOpen"],
  data() {
    return {
      show: this.showmenu,
      isOpens: this.isOpen,
      childActive: false,
      subMenusO: this.subMenus
    };
  },
  methods: {
    navigateTo(url: string) {
      window.history.pushState({}, '', url);
    },
    navigateToHome(url: string) {
      window.history.pushState({}, '', url);
    },
    enter(el:any) {
        el.style.height = 'auto';
        const height = getComputedStyle(el).height;
        el.style.height = 0;
        getComputedStyle(el);
        setTimeout(() => {
          el.style.height = height;
        });
      },
      afterEnter(el:any) {
        el.style.height = 'auto';
      },
      leave(el:any) {
        el.style.height = getComputedStyle(el).height;
        getComputedStyle(el);
        setTimeout(() => {
          el.style.height = 0;
        });
      },
    showSubMenu() {
      this.show = !this.show;
      this.isOpens = !this.isOpens;
    },
    activeChildMenu() {
      this.childActive = true;
    },
  },
  watch:{
    $route (to, from){
      this.childActive = false;
  }
  },
  mounted() {
    if (this.subMenusO) {
      this.subMenusO.filter((subMenu: any) => {
      if(window.location.pathname  == subMenu.link) {
        return this.activeChildMenu();
      } else {
        return;
      }
    });
  }
    
},
});
